
import { PartnerRequest, PartnerRequestValue, PartnerRequestStatus } from "@/store/modules/partner-request.store";
import { formatDate } from "@/utils/formatters";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Debounce } from "@/utils/vue-helpers";
import vSelect from "vue-select";
import FormDateRangePicker from "@/components/forms/date-range.vue";
import draggable from "vuedraggable";
import { PatchPartnerRequestPayload } from "@/store/modules/partner-request.store";
import store from "@/store";

@Component({
    components: {
        vSelect,
        draggable,
        FormDateRangePicker,
        GeneralHeaderBar: () => import("@/components/general/header-bar.vue"),
    },
})
export default class PageDashboard extends Vue {
    @Getter("partner-request/all") partnerRequests!: PartnerRequest[];
    @Getter("partner-request/values") partnerRequestValues!: PartnerRequestValue[];

    @Action("partner-request/index") indexPartnerRequests!: (payload: any) => Promise<PartnerRequest[]>;
    @Action("partner-request/patchStatus") patchPartnerRequestsStatus!: (payload: PatchPartnerRequestPayload) => Promise<PartnerRequest>;
    @Action("partner-request/values") indexPartnerRequestValues!: (payload?: any) => PartnerRequest[];

    formatDate = formatDate;

    filter: any = {
        search: "",
        zipcodes: [],
        offices: [],
        range: {
            from: "",
            to: "",
        },
    };

    q = { and: { or: {}, and: { or: {}, and: { or: {}, and: { or: {} } } } } };

    get columns() {
        return [
            { code: PartnerRequestStatus.new, partnerRequests: this.partnerRequests.filter((a) => a.status === PartnerRequestStatus.new) },
            { code: PartnerRequestStatus.planned, partnerRequests: this.partnerRequests.filter((a) => a.status === PartnerRequestStatus.planned) },
            { code: PartnerRequestStatus.done, partnerRequests: this.partnerRequests.filter((a) => a.status === PartnerRequestStatus.done) },
        ];
    }

    get offices() {
        if (!this.partnerRequestValues) {
            return;
        }

        const partnerRequestValue = this.partnerRequestValues.find((prv) => prv.field === "values.office");
        return partnerRequestValue?.results.map((r) => {
            return { label: r.term, value: r.term };
        });
    }

    get zipcodes() {
        if (!this.partnerRequestValues) {
            return;
        }

        const partnerRequestValue = this.partnerRequestValues.find((prv) => prv.field === "values.zip");
        return partnerRequestValue?.results.map((r) => {
            return { label: r.term, value: r.term };
        });
    }

    checkMove(event: any) {
        const fromColumn = event.from.dataset.column;
        const toColumn = event.to.dataset.column;

        document.querySelectorAll(".column.on-hover").forEach((column) => {
            column.classList.remove("on-hover");
        });

        event.to.classList.add("on-hover");

        if (fromColumn === toColumn) {
            return false;
        }

        return true;
    }

    async moveEnded(event: { item: { _underlying_vm_: { id: number } }; to: HTMLElement; from: HTMLElement }) {
        const eventId = event.item._underlying_vm_.id;
        const fromColumn = event.from.dataset.column;
        const toColumn = event.to.dataset.column;

        document.querySelectorAll(".column.on-hover").forEach((column) => {
            column.classList.remove("on-hover");
        });

        if (fromColumn === toColumn) {
            return;
        }

        let partnerRequest = this.partnerRequests.find((partnerRequest: PartnerRequest) => partnerRequest.id === eventId);
        if (!partnerRequest) {
            return;
        }

        try {
            store.commit("partner-request/UPDATE_MODEL", { ...partnerRequest, status: toColumn, saving: true });

            await this.patchPartnerRequestsStatus({ id: partnerRequest.id, status: toColumn as PartnerRequestStatus });
        } catch (e: any) {
            // if (e.errors && e.errors[0] && e.errors[0].message && e.errors[0].message === "transition_exception") {
            //     bus.$emit("show-transition-failed", { status: e.errors[0].params, activityId: activity.id });
            // }
            // if (e.errors && e.errors[0] && e.errors[0].message && e.errors[0].message === "validation.can_transition") {
            //     bus.$emit("show-transition-failed", { status: [e.errors[0].message], activityId: activity.id });
            // }
            // if (e.errors && e.errors[0] && e.errors[0].message && e.errors[0].message === "validation.required_if") {
            //     bus.$emit("show-activity-lost-reason", activity.id);
            // }
            //
            store.commit("partner-request/UPDATE_MODEL", { ...partnerRequest, status: fromColumn, saving: false });
        }
    }

    async mounted() {
        await this.indexPartnerRequestValues();

        await this.indexPartnerRequests({ per_page: 999 });
    }

    @Watch("filter.search")
    handleSearchChanged(newValue: string) {
        this.q.and.or = { "activity.name": [`like:*${newValue}*`, `match:${newValue}`] };
        this.searchPartners();
    }

    @Watch("filter.range", { deep: true })
    handleDateChanged(newValue: { from: string; to: string }) {
        //@ts-ignore
        (this.q.and.and ?? {}).created_at = [`gte:${newValue.from}`, `lte:${newValue.to}`];
        this.searchPartners();
    }

    @Watch("filter.zipcodes")
    handleZipcodesChanged(newValue: string[]) {
        //@ts-ignore
        this.q.and.and.and.or["values.zip"] = newValue;
        this.searchPartners();
    }

    @Watch("filter.offices")
    handleOfficesChanged(newValue: string[]) {
        //@ts-ignore
        this.q.and.and.and.and.or["values.office"] = newValue;
        this.searchPartners();
    }

    @Debounce(500)
    async searchPartners() {
        await this.indexPartnerRequests({ per_page: 999, q: this.q });
    }
}
